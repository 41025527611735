// Fonts: Public Sans + Newsreader + Source Code Pro
// https://fonts.google.com/specimen/Public+Sans
// https://fonts.google.com/specimen/Newsreader
// https://fonts.google.com/specimen/Source+Code+Pro

@import url("https://fonts.googleapis.com/css2?family=Newsreader:ital,wght@0,400;0,700;1,400;1,700&family=Public+Sans:ital,wght@0,100;0,400;0,600;0,700;0,900;1,100;1,400;1,600;1,700;1,900&display=swap");

// Default variable overrides
$border-radius: 0;
//$body-bg: rgb(52, 57, 62);
$body-color: #252525;
$btn-close-color: $body-color;
$blue: #1f78b4;
// $indigo:  #6610f2;
// $purple:  #6f42c1;
// $pink:    #d63384;
// $red:     #dc3545;
$orange: #d95f02;
$yellow: #dead3b;
$green: #4a9c79;
// $teal:    #20c997;
// $cyan:    #0dcaf0;

$secondary: #636363;
$light: hsl(140, 10%, 94%);

$font-family-sans-serif: "Public Sans", -apple-system, system-ui,
  BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-serif: Newsreader, Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace: Menlo, Monaco, Consolas, "Liberation Mono",
  "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;

$breadcrumb-divider: quote(">");
// $breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='white'/%3E%3C/svg%3E");
$form-select-indicator-color: $body-color;

$form-select-indicator-color: $body-color;

// $modal-content-bg: rgb(52, 57, 62);

@import "~bootstrap/scss/bootstrap.scss";

.breadcrumb {
  margin-bottom: 0px !important;
  font-size: smaller;
}

@include media-breakpoint-up(sm) {
  .breadcrumb {
    font-size: unset;
  }
}

h1 {
  @extend .pb-4;
}

h2 {
  @extend .py-3;
}

h3,
h4 {
  @extend .py-2;
}

// For the footer
#content {
  overflow-y: visible;
  min-height: 100px;
  margin-top: 10px;
}

#navheader {
  @extend .sticky-top;
}

@include media-breakpoint-up(md) {
  #content {
    // subtract the height for the menu, footer, and their padding
    height: calc(100vh - 56px - 24px - 10px - 16px - 16px);
    overflow-y: auto;
  }
}

// BB specific styles below
* {
  border-radius: 0 !important;
}

body {
  margin: 0;
  height: 100%;
  min-height: 100vh;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  background-color: #ffeaa7;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input:focus {
  outline: none;
  box-shadow: 0px 0px 5px white;
}
input {
  border: none;
  padding: 10px;
  font-family: monospace;
}
button {
  font-family: monospace;
  color: #2d3436;
  border: 1px solid #2d3436;
  background-color: transparent;
  padding: 5px;
  cursor: pointer;
  margin: 2px;
  outline:none;
  display: inline-block;
}
#root {
  display: flex;
}
a {
  text-decoration: none;
  color: black;
}
a:hover {
  text-decoration: none;
  color: black;
}
a:visited {
  text-decoration: none;
  color: black;
}
tbody >:nth-child(even) {
	background-color: white;
}
table {
	border-collapse: collapse;
	width: 100%;
}
table tr {
	background-color: #ddd;
}
table th {
	padding: 5px;
}
table td {
	min-height: 15px;
	padding: 5px;
}
td a {
  display: block;
  width: 100%;
  height: 100%;
}
.error {
  padding: 5px;
  text-align: center;
  color: white;
  background-color: #e55039;
}
.side {
  height: 50px;
  width: 50px;
}
.center {
  flex-grow: 1;
}
.side + .side {
  margin-left: 2%;
}
.fade-out {
  animation: fadeOut 2s linear 0.5s 1 normal forwards;
  -webkit-animation: fadeOut 2s linear 0.5s 1 normal forwards;
  -moz-animation: fadeOut 2s linear 0.5s 1 normal forwards;
  -o-animation: fadeOut 2s linear 0.5s 1 normal forwards;
  -ms-animation: fadeOut 2s linear 0.5s 1 normal forwards;
  -webkit-animation: fadeOut 2s linear 0.5s 1 normal forwards;
}
@keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}
@-moz-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}
@-webkit-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}
@-o-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}
@-ms-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}
.bg-img {
  opacity: 0.5;
  background-image: url(/../public/bg.jpg);
  background-size: cover;
}
tbody.striped >:nth-child(odd) {
	background-color: #F8F8F8;
}
tbody.striped >:nth-child(even) {
	background-color: white;
}
@media print {
  .side {
    display: none !important;
  }
  .fullsize-image-container {
    display: block !important;
  }
  .page-break { 
    page-break-before: always;
  }
  img {
    page-break-inside: avoid;
  }
}
